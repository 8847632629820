<template>
  <div
    class="modal-backdrop"
    v-bind:class="{ mantle: isActive }"
    @click="quXiao"
    @touchmove.prevent="rowing"
  >
    <div class="modal">
      <div class="modal-header">
        <p class="ignore">{{ title }}</p>
      </div>
      <div class="modal-body">
        <p @click.stop="callPhone(15726681298)">{{ telephoneNumbers }}</p>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn-close" @click="closeSelf">关闭</button>
            <button type="button" class="btn-confirm" @click="confirm">确认</button> -->
        <p @click.stop="pricingCalls(4001886722)">{{ pricingCall }}</p>
      </div>
    </div>
    <!-- 取消 -->
    <div @click="quXiao" class="modal-cancel">
      <p>{{ cancel }}</p>
    </div>
  </div>
</template>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding-bottom: 140px;
  z-index: 99999;
}

.mantle {
  display: none;
  /* display: block; */
}

.modal-cancel {
  display: flex;
  background-color: #fff;
  width: 700px;
  height: 100px;
  overflow-x: auto;
  border-radius: 16px;
  font-size: 36px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: rgba(0, 145, 255, 1);
}

.modal {
  background-color: #fff;
  /* box-shadow: 2px 2px 20px 1px; */
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 700px;
  font-size: 36px;
}
.modal-header {
  border-bottom: 1px solid #eee;
  color: rgba(179, 179, 179, 1);
  padding: 15px;
  display: flex;
  font-size: 32px;
  justify-content: center;
  padding: 20px 0;
}
.modal-footer {
  border-top: 1px solid #eee;
  justify-content: center;
  padding: 15px;
  display: flex;
  color: rgba(0, 145, 255, 1);
}
.modal-body {
  position: relative;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  color: rgba(0, 145, 255, 1);
}
.btn-close,
.btn-confirm {
  border-radius: 8px;
  margin-left: 16px;
  width: 56px;
  height: 36px;
  border: none;
  cursor: pointer;
}
.btn-close {
  color: #313131;
  background-color: transparent;
}
.btn-confirm {
  color: #fff;
  background-color: #2d8cf0;
}

body.modalOpen {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  width: 100%;
}
</style>

<script>
export default {
  name: "phone",
  props: {
    title: String,
    telephoneNumbers: String,
    pricingCall: String,
    cancel: String,
    display: String,
    isphone: String,
    isActive: Boolean
  },
  data() {
    return {
      mockScrollTop: null, // 距离顶端的值
      className: "modalOpen",
      hideShow: false // 模态框默认不显示
    };
  },
  mounted() {
    this.dis = `background:${this.display}`;
  },
  methods: {
    closeSelf() {
      this.$emit("closeme");
    },
    callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
    pricingCalls(phoneNumbers) {
      window.location.href = "tel://" + phoneNumbers;
    },
    quXiao(e) {
      // console.info(e.target)
      const str = e.target.className;
      // console.info(str)
      // console.info(str.indexOf('ignore'))
      if (str.indexOf("ignore") != -1) {
        // console.info(e)
        e.stopPropagation();
        return;
      }
      // e.target.
      this.$emit("closeme");
    },

    rowing() {
      this.hideShow = !this.hideShow;
      if (this.hideShow) {
        this.mockScrollTop = document.scrollingElement.scrollTop;
        document.body.classList.add(this.className);
        document.body.style.top = `-${this.mockScrollTop}px`;
      } else {
        document.body.classList.remove(this.className);
        document.scrollingElement.scrollTop = this.mockScrollTop;
      }
    }
  }
};
</script>
